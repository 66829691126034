import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Order = () => {
  const [formData, setFormData] = useState({
    organizationName: '',
    organizationAddress: '',
    email: '',
    numberOfDevices: '',
    otherQuery: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      // Form is valid, you can submit it or perform further actions here
      console.log("Form data:", formData);
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.organizationName.trim()) {
      errors.organizationName = 'Organization Name is required';
    }

    if (!data.organizationAddress.trim()) {
      errors.organizationAddress = 'Organization Address is required';
    }

    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(data.email)) {
      errors.email = 'Invalid email format';
    }

    if (!data.numberOfDevices.trim()) {
      errors.numberOfDevices = 'Number of Devices is required';
    } else if (isNaN(data.numberOfDevices) || parseInt(data.numberOfDevices) <= 0) {
      errors.numberOfDevices = 'Please enter a valid number of devices';
    }

    if (data.otherQuery.length > 200) {
      errors.otherQuery = 'Other Query should be up to 200 words';
    }

    return errors;
  };

  const isValidEmail = (email) => {
    // Basic email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <>
    <style>{`
    .card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card {
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card h2 {
  margin-bottom: 20px;
}

.card div {
  margin-bottom: 10px;
}

.card label {
  display: block;
  font-weight: bold;
}

.card input,
.card textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.card button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.card button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

    `}</style>
    <Navbar/>
    <div style={{marginTop:"100px"}}>
    <div className="card-container">
      <div className="card">
        <h2>Order Now</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="organizationName">Organization Name:</label>
            <input type="text" id="organizationName" name="organizationName" value={formData.organizationName} onChange={handleChange} />
            {errors.organizationName && <p className="error">{errors.organizationName}</p>}
          </div>
          <div>
            <label htmlFor="organizationAddress">Organization Address:</label>
            <input type="text" id="organizationAddress" name="organizationAddress" value={formData.organizationAddress} onChange={handleChange} />
            {errors.organizationAddress && <p className="error">{errors.organizationAddress}</p>}
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="numberOfDevices">Number of Devices:</label>
            <input type="text" id="numberOfDevices" name="numberOfDevices" value={formData.numberOfDevices} onChange={handleChange} />
            {errors.numberOfDevices && <p className="error">{errors.numberOfDevices}</p>}
          </div>
          <div>
            <label htmlFor="otherQuery">Other Query:</label>
            <textarea id="otherQuery" name="otherQuery" value={formData.otherQuery} onChange={handleChange}></textarea>
            {errors.otherQuery && <p className="error">{errors.otherQuery}</p>}
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
    </div>
    <Footer/>
    </>
  );
};

export default Order;
