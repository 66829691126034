// import React from 'react';
// // import './navbar.css';

// const Banner3 = () => {
//   return (
//     <>
//     <style>{`

// .devices-container {
//   margin: 20px;
// }

// .device-list {
//   list-style-type: none;
//   padding: 0;
// }

// .device-item {
//   background-color: #f4f4f4;
//   padding: 10px;
//   margin-bottom: 5px;
//   border-radius: 5px;
// }

// .device-item:hover {
//   background-color: #e0e0e0;
// }


// .main-section {
//   padding: 50px 0;
// }

// .containers {
//   max-width: 1200px;
//   margin: 0 auto;
// }

// .approved-by-inside {
//   display: flex;
//   align-items: center;
// }

// .columns {
//   display: flex;
// }

// .column {
//   flex: 1;
// }

// .images {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .images img {
//   width: 150px; /* Adjust the width as needed */
//   height: auto;
//   margin-right: 20px; /* Adjust spacing between images */
// }

// .approved-content {
//   padding: 0 20px; /* Adjust padding as needed */
// }

// h1 {
//   font-size: 26px;
//   margin-bottom: 10px;
// }

// p {
//   font-size: 18px;
//   line-height: 1.6;
// }

// p span {
//   font-weight: bold;
// }

// /* Media queries for responsiveness */
// @media (max-width: 768px) {
//   .columns {
//     flex-direction: column;
//   }

//   .images {
//     margin-bottom: 20px;
//   }

//   .images img {
//     margin-right: 20px;
//     margin-bottom: 10px;
//   }
// }
//     `}</style>
//     <section className="main-section approved-by">
//       <div className="containers">
//         <div className="approved-by-inside">
//           <div className="columns">
//             <div className="column is-6-desktop is-6-tablet is-12-mobile">
//               <div className="images">
//                 <img loading="lazy" src="nta-logo.png" alt="nta" />
//                 <img loading="lazy" src="nepallogo.jpeg" alt="Government-of-Nepal" />
//               </div>
//             </div>
//             <div className="column is-6-desktop is-6-tablet is-12-mobile">
//               <div className="approved-content">
//                 <h1>Approved By</h1>
//                 <p>
//                   All our GPS tracking devices are Type Approved by the
//                   <span>Nepal Telecommunications Authority (NTA)</span> and
//                   Licensed by the
//                   <span> Ministry of Communication and Information Technology, Government of Nepal</span>.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//     </>
//   );
// };

// export default Banner3;


// import React from 'react';

// const Banner3 = () => {
//   return (
//     <div className="container">
//       <style>{`
//       .devices-container {
//   margin: 20px;
// }

// .device-list {
//   list-style-type: none;
//   padding: 0;
// }

// .device-item {
//   background-color: #f4f4f4;
//   padding: 10px;
//   margin-bottom: 5px;
//   border-radius: 5px;
// }

// .device-item:hover {
//   background-color: #e0e0e0;
// }


// .main-section {
//   padding: 50px 0;
// }

// .containers {
//   max-width: 1200px;
//   margin: 0 auto;
// }

// .approved-by-inside {
//   display: flex;
//   align-items: center;
// }

// .columns {
//   display: flex;
// }

// .column {
//   flex: 1;
// }

// .images {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .images img {
//   width: 150px; /* Adjust the width as needed */
//   height: auto;
//   margin-right: 20px; /* Adjust spacing between images */
// }

// .approved-content {
//   padding: 0 20px; /* Adjust padding as needed */
// }

// h1 {
//   font-size: 26px;
//   margin-bottom: 10px;
// }

// p {
//   font-size: 18px;
//   line-height: 1.6;
// }

// p span {
//   font-weight: bold;
// }

// /* Media queries for responsiveness */
// @media (max-width: 768px) {
//   .columns {
//     flex-direction: column;
//   }

//   .images {
//     margin-bottom: 20px;
//   }

//   .images img {
//     margin-right: 20px;
//     margin-bottom: 10px;
//   }
// }

//         /* Animation for the container */
//         @keyframes fadeIn {
//           from {
//             opacity: 0;
//             transform: translateY(20px);
//           }
//           to {
//             opacity: 1;
//             transform: translateY(0);
//           }
//         }

//         .container {
//           animation: fadeIn 0.5s ease-in-out; /* Apply animation */
//         }
//       `}</style>
//       <section className="main-section approved-by">
//         <div className="containers">
//           <div className="approved-by-inside">
//             <div className="columns">
//               <div className="column is-6-desktop is-6-tablet is-12-mobile">
//                 <div className="images">
//                   <img loading="lazy" src="nta-logo.png" alt="nta" />
//                   <img loading="lazy" src="nepallogo.jpeg" alt="Government-of-Nepal" />
//                 </div>
//               </div>
//               <div className="column is-6-desktop is-6-tablet is-12-mobile">
//                 <div className="approved-content">
//                   <h1>Approved By</h1>
//                   <p>
//                     All our GPS tracking devices are Type Approved by the
//                     <span>Nepal Telecommunications Authority (NTA)</span> and
//                     Licensed by the
//                     <span> Ministry of Communication and Information Technology, Government of Nepal</span>.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Banner3;


import React from 'react';

const Banner3 = () => {
  return (
    <div className="container">
      <style>{`
        .container {
          position: relative;
          overflow: hidden; 
          height:250px;

        }

        {/* .animated-background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #4CAF50;
          animation: animateBackground 10s linear infinite;
        }

        /* Animation keyframes */
        @keyframes animateBackground {
          0% {
            transform: translateX(-100%); 
          }
          100% {
            transform: translateX(100%);
          }
        } */}

        .main-section {
          padding: 50px 0;
        }

        .containers {
          max-width: 1200px;
          margin: 0 auto;
          position: relative;
          z-index: 1;
        }

        .approved-by-inside {
          display: flex;
          align-items: center;
        }

        .columns {
          display: flex;
        }

        .column {
          flex: 1;
        }

        .images {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .images img {
          width: 150px;
          height: auto;
          margin-right: 90px;
        }

        .approved-content {
          padding: 0 15px;
        }

       .h1 {
          font-size: 26px;
          margin-top: 30px;
        }

        p {
          font-size: 18px;
          line-height: 1.6;
        }

        p span {
          font-weight: bold;
        }
        @media (max-width: 768px) {
          .columns {
            flex-direction: column;
          }

          .images {
            {/* margin-bottom: 20px; */}
            margin-left:100px;

          }

          .images img {
            margin-right: 20px;
            margin-bottom: 10px;
          }
        }
      `}</style>
      <div className="animated-background"></div>
      <section className="main-section approved-by">
        <div className="containers">
          <div className="approved-by-inside">
            <div className="columns">
              <div className="column is-6-desktop is-6-tablet is-12-mobile">
                <div className="images">
                  <img loading="lazy" src="nta-logo.png" alt="nta" />
                  <img loading="lazy" src="nepallogo1.png" alt="Government-of-Nepal" />
                </div>
              </div>
              <div className="column is-6-desktop is-6-tablet is-12-mobile">
                <div className="approved-content">
                  <h1 className='h1'>Aproved & Licenced by government of Nepal.</h1>
                  {/* <p>
                    All our GPS tracking devices are Type Approved by the
                    <span>Nepal Telecommunications Authority (NTA)</span> and
                    Licensed by the
                    <span> Ministry of Communication and Information Technology, Government of Nepal</span>.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner3;


