import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const SchoolBus = () => {
  return (
    <>
      <style>
        {`
        .full-width-image-container {
  width: 100%;
  height:90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative; /* Add position relative */
}

.full-width-image {
  min-width: 100%; /* Use min-width instead of width */
  min-height: 100%; /* Use min-height instead of height */
  object-fit: cover; /* Ensure the image covers the container */
  position: absolute; /* Position the image absolutely */
  top: 50%; /* Move the image vertically to the center */
  left: 50%; /* Move the image horizontally to the center */
  transform: translate(-50%, -50%); /* Translate the image back by half of its own dimensions */
}

        .table {
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto;
          padding: 2rem 0rem 0rem 0rem;
          max-width: 1100px;
        }
        .table-cell {
          box-sizing: border-box;
          flex-grow: 1;
          width: 100%;
          padding: 0.8em 1.2em;
          overflow: hidden;
          list-style-type: none;
          outline: 1px solid #ddd;
          text-align: center;
          font-weight: 300;
          color: #4a4a8d;
          margin: {
            top: 1px;
            left: 1px;

          }
          &:first-child {
            outline: 1px solid #fff;
            background: transparent;
            @media only screen and (max-width : 768px) {
              display: none;
            }
          }
          &:nth-child(3) {
            outline: 1px solid #30305b;
          }
          &:nth-child(-n+3) {
            padding: {
              top: 40px;
              bottom: 40px;
            }
          }
          > h3 {
            font-size: 26px;
            margin: {
              top: 0;
              bottom: 3rem;
            }
          }
          &.cell-feature {
            text-align: left;
            font-size: 18px;
          }
          &.plattform {
            color: limegreen;
            a.btn {
              color: limegreen;
              &:hover {
                background-color: limegreen;
                border-color: limegreen;
                color: white;
              }
            }
          }
          &.enterprise {
            background-color: #30305b;
            color: #85bafc;
            a.btn {
              color: #85bafc;
              &:hover {
                background-color: #85bafc;
                border-color: #85bafc;
                color: #30305b;
              }
            }
          }
        }
        /* Table columns */
        .table-cell  {
          width: calc(33.33% - 1px);
          &.cell-feature {
            @media only screen and (max-width : 768px) {
              width: 100%;
              text-align: center;
            }
          }
        }
        svg.enterprise-check path {
          fill: #30305b !important;
        }
        a.btn {
          border: 2px solid;
          padding: .6rem .9rem .6rem .9rem;
          font-weight: 400;
          text: {
            transform: uppercase;
            decoration: none;
          }
          transition: 0.2s ease-in-out;
          border-radius: .3rem;
        }


        .tick-cross-container {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f0f0f0;
          margin: 0 auto;
        }
       
        `}
      </style>
      <Navbar/>
      <div style={{marginTop:"60px"}}>
      <div className="full-width-image-container">
        <img className="full-width-image" src="schoolbanner.jpg" alt="Image" />
      </div>
      <div className="table">
        <div class="table-cell">
            <h3>Our Features</h3>
        </div>
        <div class="table-cell plattform">
          <h3>Z1 Tracker</h3>
          {/* <a href="" class="btn">register now</a> */}
        </div>
        <div class="table-cell enterprise">
          <h3>Other Devices</h3>
          {/* <a href="" class="btn">Send us a Email</a> */}
        </div>
        <div class="table-cell cell-feature">Instant delivery</div>
        <div className="table-cell">
        <div className="tick-cross-container">
            <svg className="enterprise-check" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>right_tick</title>
              <path d="M6.116 14.884c.488.488 1.28.488 1.768 0l10-10c.488-.488.488-1.28 0-1.768s-1.28-.488-1.768 0l-9.08 9.15-4.152-4.15c-.488-.488-1.28-.488-1.768 0s-.488 1.28 0 1.768l5 5z" fill="limegreen" fillRule="evenodd" />
            </svg>
          </div>
          </div>
          <div className="table-cell">
          <div className="tick-cross-container">
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>cross</title>
              <path d="M14.87 3.13a.996.996 0 0 0-1.41 0L9 7.59 5.41 4a.996.996 0 1 0-1.41 1.41L7.59 9 4 12.59a.996.996 0 1 0 1.41 1.41L9 10.41l3.59 3.59a.996.996 0 1 0 1.41-1.41L10.41 9l3.59-3.59c.39-.39.39-1.02 0-1.41z" fill="#ff0000" />
            </svg>
          </div>
          </div>
        <div class="table-cell cell-feature">Real-time Tracking</div>
        <div className="table-cell">
        <div className="tick-cross-container">
            <svg className="enterprise-check" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>right_tick</title>
              <path d="M6.116 14.884c.488.488 1.28.488 1.768 0l10-10c.488-.488.488-1.28 0-1.768s-1.28-.488-1.768 0l-9.08 9.15-4.152-4.15c-.488-.488-1.28-.488-1.768 0s-.488 1.28 0 1.768l5 5z" fill="limegreen" fillRule="evenodd" />
            </svg>
          </div>
          </div>
          <div className="table-cell">
          <div className="tick-cross-container">
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>cross</title>
              <path d="M14.87 3.13a.996.996 0 0 0-1.41 0L9 7.59 5.41 4a.996.996 0 1 0-1.41 1.41L7.59 9 4 12.59a.996.996 0 1 0 1.41 1.41L9 10.41l3.59 3.59a.996.996 0 1 0 1.41-1.41L10.41 9l3.59-3.59c.39-.39.39-1.02 0-1.41z" fill="#ff0000" />
            </svg>
          </div>
          </div>
        <div class="table-cell cell-feature">Ignition Status</div>
        <div className="table-cell">
        <div className="tick-cross-container">
            <svg className="enterprise-check" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>right_tick</title>
              <path d="M6.116 14.884c.488.488 1.28.488 1.768 0l10-10c.488-.488.488-1.28 0-1.768s-1.28-.488-1.768 0l-9.08 9.15-4.152-4.15c-.488-.488-1.28-.488-1.768 0s-.488 1.28 0 1.768l5 5z" fill="limegreen" fillRule="evenodd" />
            </svg>
          </div>
          </div>
          <div className="table-cell">
          <div className="tick-cross-container">
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>cross</title>
              <path d="M14.87 3.13a.996.996 0 0 0-1.41 0L9 7.59 5.41 4a.996.996 0 1 0-1.41 1.41L7.59 9 4 12.59a.996.996 0 1 0 1.41 1.41L9 10.41l3.59 3.59a.996.996 0 1 0 1.41-1.41L10.41 9l3.59-3.59c.39-.39.39-1.02 0-1.41z" fill="#ff0000" />
            </svg>
          </div>
          </div>
        <div class="table-cell cell-feature">Fuel Monitoring</div>
        <div className="table-cell">
        <div className="tick-cross-container">
            <svg className="enterprise-check" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>right_tick</title>
              <path d="M6.116 14.884c.488.488 1.28.488 1.768 0l10-10c.488-.488.488-1.28 0-1.768s-1.28-.488-1.768 0l-9.08 9.15-4.152-4.15c-.488-.488-1.28-.488-1.768 0s-.488 1.28 0 1.768l5 5z" fill="limegreen" fillRule="evenodd" />
            </svg>
          </div>
          </div>
          <div className="table-cell">
          <div className="tick-cross-container">
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>cross</title>
              <path d="M14.87 3.13a.996.996 0 0 0-1.41 0L9 7.59 5.41 4a.996.996 0 1 0-1.41 1.41L7.59 9 4 12.59a.996.996 0 1 0 1.41 1.41L9 10.41l3.59 3.59a.996.996 0 1 0 1.41-1.41L10.41 9l3.59-3.59c.39-.39.39-1.02 0-1.41z" fill="#ff0000" />
            </svg>
          </div>
          </div>
        <div class="table-cell cell-feature">Over-Speeding Report</div>
        <div className="table-cell">
        <div className="tick-cross-container">
            <svg className="enterprise-check" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>right_tick</title>
              <path d="M6.116 14.884c.488.488 1.28.488 1.768 0l10-10c.488-.488.488-1.28 0-1.768s-1.28-.488-1.768 0l-9.08 9.15-4.152-4.15c-.488-.488-1.28-.488-1.768 0s-.488 1.28 0 1.768l5 5z" fill="limegreen" fillRule="evenodd" />
            </svg>
          </div>
          </div>
          <div className="table-cell">
          <div className="tick-cross-container">
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>cross</title>
              <path d="M14.87 3.13a.996.996 0 0 0-1.41 0L9 7.59 5.41 4a.996.996 0 1 0-1.41 1.41L7.59 9 4 12.59a.996.996 0 1 0 1.41 1.41L9 10.41l3.59 3.59a.996.996 0 1 0 1.41-1.41L10.41 9l3.59-3.59c.39-.39.39-1.02 0-1.41z" fill="#ff0000" />
            </svg>
          </div>
          </div>
        <div class="table-cell cell-feature">Location History ( 90 Days )</div>
        <div className="table-cell">
        <div className="tick-cross-container">
            <svg className="enterprise-check" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>right_tick</title>
              <path d="M6.116 14.884c.488.488 1.28.488 1.768 0l10-10c.488-.488.488-1.28 0-1.768s-1.28-.488-1.768 0l-9.08 9.15-4.152-4.15c-.488-.488-1.28-.488-1.768 0s-.488 1.28 0 1.768l5 5z" fill="limegreen" fillRule="evenodd" />
            </svg>
          </div>
          </div>
          <div className="table-cell">
          <div className="tick-cross-container">
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>cross</title>
              <path d="M14.87 3.13a.996.996 0 0 0-1.41 0L9 7.59 5.41 4a.996.996 0 1 0-1.41 1.41L7.59 9 4 12.59a.996.996 0 1 0 1.41 1.41L9 10.41l3.59 3.59a.996.996 0 1 0 1.41-1.41L10.41 9l3.59-3.59c.39-.39.39-1.02 0-1.41z" fill="#ff0000" />
            </svg>
          </div>
          </div>
        <div class="table-cell cell-feature">Geo-Fencing</div>
        <div className="table-cell">
        <div className="tick-cross-container">
            <svg className="enterprise-check" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>right_tick</title>
              <path d="M6.116 14.884c.488.488 1.28.488 1.768 0l10-10c.488-.488.488-1.28 0-1.768s-1.28-.488-1.768 0l-9.08 9.15-4.152-4.15c-.488-.488-1.28-.488-1.768 0s-.488 1.28 0 1.768l5 5z" fill="limegreen" fillRule="evenodd" />
            </svg>
          </div>
          </div>
          <div className="table-cell">
          <div className="tick-cross-container">
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>cross</title>
              <path d="M14.87 3.13a.996.996 0 0 0-1.41 0L9 7.59 5.41 4a.996.996 0 1 0-1.41 1.41L7.59 9 4 12.59a.996.996 0 1 0 1.41 1.41L9 10.41l3.59 3.59a.996.996 0 1 0 1.41-1.41L10.41 9l3.59-3.59c.39-.39.39-1.02 0-1.41z" fill="#ff0000" />
            </svg>
          </div>
          </div>
        <div class="table-cell cell-feature">24/7 support</div>
        <div className="table-cell">
        <div className="tick-cross-container">
            <svg className="enterprise-check" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>right_tick</title>
              <path d="M6.116 14.884c.488.488 1.28.488 1.768 0l10-10c.488-.488.488-1.28 0-1.768s-1.28-.488-1.768 0l-9.08 9.15-4.152-4.15c-.488-.488-1.28-.488-1.768 0s-.488 1.28 0 1.768l5 5z" fill="limegreen" fillRule="evenodd" />
            </svg>
          </div>
          </div>
          <div className="table-cell">
          <div className="tick-cross-container">
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <title>cross</title>
              <path d="M14.87 3.13a.996.996 0 0 0-1.41 0L9 7.59 5.41 4a.996.996 0 1 0-1.41 1.41L7.59 9 4 12.59a.996.996 0 1 0 1.41 1.41L9 10.41l3.59 3.59a.996.996 0 1 0 1.41-1.41L10.41 9l3.59-3.59c.39-.39.39-1.02 0-1.41z" fill="#ff0000" />
            </svg>
          </div>
          </div>
      </div>
      </div>
      <Footer/>
    </>
  );
};

export default SchoolBus;
