import React from 'react'
import Navbar from '../components/Navbar';
import Banner10 from '../components/Banner10';
import Footer from '../components/Footer';

const Bikes = () => {
  return (
  <>
  <Navbar/>
  <Banner10/>
  <Footer/>
    </>
  )
}

export default Bikes;
