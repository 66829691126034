import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import Banner2 from '../components/Banner2'
import Banner3 from '../components/Banner3'
import Banner4 from '../components/Banner4'
import Banner5 from '../components/Banner5'
// import Banner6 from '../components/Banner6'
import Banner7 from '../components/Banner7'
import Banner8 from '../components/Banner8'
import Banner9 from '../components/Banner9'
import Banner10 from '../components/Banner10'

const Home = () => {
  return (
    <>
    <Navbar/>
    <Banner/>
    <Banner2/>
    <Banner3/>
    <Banner4/>
    <Banner5/>
    {/* <Banner6/> */}
    <Banner7/>
    <Banner8/>
    <Banner9/>
   <Banner10/>
    <div><Footer/></div>
    </>

  )
}

export default Home