import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const Services = () => {
  return (
    <>
    <Navbar/>
    <div className='body'>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '20px', color: '#333' }}>Welcome Services Page</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ flex: 1, textAlign: 'left' }}>
          <h1>CEO Voice</h1>
          <h2>Soft.Er. Bhanu Marasini</h2>
          <p>
          Vehicle GPS Tracking system, 
          is a pivotal tool in modern fleet management. It ensures precise monitoring and management of vehicles, 
          optimizing efficiency and enhancing overall operational control. With its capability to 
          provide real-time insights and historical data analysis, our GPS tracking system empowers businesses to 
          make informed decisions, streamline processes, and maximize productivity."
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <img src="/intro.jpeg" alt="intro" style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px', boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)' }} />
        </div>
      </div>
      <div style={{ marginTop: '50px', textAlign: 'left' }}>
        <h2>Contact Us</h2>
        <p>Email: bhanu@ceo.com</p>
        <p>Phone: +977 9857050123</p>
        <p>Address: Zonestreet, Bahirahwa, Nepal</p>
      </div>
    </div>
    <Footer/>
</>
  );
}

export default Services;
