// import React from 'react';
// import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';

// const Extra = () => {
//   return (
//     <>
//       <style>{`
//         .video-container {
//           position: relative;
//           width: 50%;
//           height: 100vh; 
//         }

//         .video {
//           width: 100%;
//           height: 100%;
//         }

//         .overlay {
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           background-color: rgba(0, 0, 0, 0.5);
//           opacity: 0;
//           transition: opacity 0.3s ease;
//         }

//         .video-container:hover .overlay {
//           opacity: 1;
//         }
//       `}</style>
//       <Navbar/>
//       <div className='body'>
//         <div className='video-container'>
//           <video className='video' autoPlay loop>
//             <source src='guide.mp4' type='video/mp4' />
//             <source src='guideAudio.mp3' type='audio/mpeg' />
//           </video>
//           <div className='overlay'></div>
//         </div>
//       </div>
//       <Footer/>
//     </>
//   );
// }

// export default Extra;

import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Extra = () => {
  return (
    <>
      <style>{`
        .video-container {
          position: relative;
          width: 100%;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .video {
          width:100%; /* Adjust the width of the video */
          height: 100%; /* Maintain aspect ratio */
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        {/* .video-container:hover .overlay {
          opacity: 1;
        } */}
      `}</style>
      <Navbar />
      <div className='body'>
        <div className='video-container'>
          <iframe
            className='video'
            src='https://www.youtube.com/embed/B9oSvPaXaiU?autoplay=1'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
          <div className='overlay'></div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Extra;
