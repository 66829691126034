// import React from 'react'
// import "./navbar.css"

// const Banner5 = () => {
//   return (
//     <section class="main-section banner5">
//     <div class="container">
//       <h1 class="banner-title">Banner Title</h1>
//       <div class="columns">
//         <div class="column is-3-desktop is-6-tablet is-12-mobile">
//           <div class="card-started">
//             <div class="card-title-number">
//               <div><span class="icon"><i class="mdi mdi-chip mdi-24px"></i></span></div>
//             </div>
//             <div class="card-content-get">
//               <h2>You will need a GPS Tracker</h2>
//               <p>A GPS Tracker is needed to transmit the real-time location of the vehicle to the Z1 Tracking cloud server.</p>
//             </div>
//           </div>
//         </div>
//         <div class="column is-3-desktop is-6-tablet is-12-mobile">
//           <div class="card-started">
//             <div class="card-title-number">
//               <div><span class="icon"><i class="mdi mdi-sim mdi-24px"></i></span></div>
//             </div>
//             <div class="card-content-get">
//               <h2>You will need a SIM card</h2>
//               <p>The GPS Tracker needs internet connectivity to send the data over the GSM network.</p>
//             </div>
//           </div>
//         </div>
//         <div class="column is-3-desktop is-6-tablet is-12-mobile">
//           <div class="card-started">
//             <div class="card-title-number">
//               <div><span class="icon"><i class="mdi mdi-tools mdi-24px"></i></span></div>
//             </div>
//             <div class="card-content-get">
//               <h2>Activate the GPS Tracker</h2>
//               <p>Download Z1 Tracking App from Playstore/Appstore and signup and call our customer support team.</p>
//             </div>
//           </div>
//         </div>
//         <div class="column is-3-desktop is-6-tablet is-12-mobile">
//           <div class="card-started">
//             <div class="card-title-number">
//               <div><span class="icon"><i class="mdi mdi-download-circle-outline mdi-24px"></i></span></div>
//             </div>
//             <div class="card-content-get">
//               <h2>Activate the GPS Tracker</h2>
//               <p>Download Z1 tracking App from Playstore/Appstore and signup and call our customer support team.</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
  
//   )
// }

// export default Banner5



// import React from 'react';
// import "./navbar.css";

// const Banner5 = () => {
//   return (
//     <section className="main-section banner5">
//       <div className="container">
//         <h1 className="banner-title">Banner Title</h1>
//         <ol className="columns">
//           <li className="column is-3-desktop is-6-tablet is-12-mobile">
//             <div className="card-started">
//               <div className="card-title-number">
//                 <div><span className="icon"><i className="mdi mdi-chip mdi-24px"></i></span></div>
//               </div>
//               <div className="card-content-get">
//                 <h2>You will need a GPS Tracker</h2>
//                 <p>A GPS Tracker is needed to transmit the real-time location of the vehicle to the Z1 Tracking cloud server.</p>
//               </div>
//             </div>
//           </li>
//           <li className="column is-3-desktop is-6-tablet is-12-mobile">
//             <div className="card-started">
//               <div className="card-title-number">
//                 <div><span className="icon"><i className="mdi mdi-sim mdi-24px"></i></span></div>
//               </div>
//               <div className="card-content-get">
//                 <h2>You will need a SIM card</h2>
//                 <p>The GPS Tracker needs internet connectivity to send the data over the GSM network.</p>
//               </div>
//             </div>
//           </li>
//           <li className="column is-3-desktop is-6-tablet is-12-mobile">
//             <div className="card-started">
//               <div className="card-title-number">
//                 <div><span className="icon"><i className="mdi mdi-tools mdi-24px"></i></span></div>
//               </div>
//               <div className="card-content-get">
//                 <h2>Install the GPS Tracker</h2>
//                 <p>Get the GPS Tracker installed with the help of any local technician near you.</p>
//               </div>
//             </div>
//           </li>
//           <li className="column is-3-desktop is-6-tablet is-12-mobile">
//             <div className="card-started">
//               <div className="card-title-number">
//                 <div><span className="icon"><i className="mdi mdi-download-circle-outline mdi-24px"></i></span></div>
//               </div>
//               <div className="card-content-get">
//                 <h2>Activate the GPS Tracker</h2>
//                 <p>Download Z1 Tracking App from Playstore/Appstore and signup and call our customer support team.</p>
//               </div>
//             </div>
//           </li>
//         </ol>
//       </div>
//     </section>
//   );
// }

// export default Banner5;



import React from 'react';
import "./navbar.css";

const Banner5 = () => {
  return (
    <>
      <style>{`
      .main-section.get-started {
  background-color: #f9f9f9;
  padding: 50px 0;
}

.get-title {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-size: 28px;
}

.card-started {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease; /* Adding transition for smoother hover effect */
}

.card-started:hover {
  transform: translateY(-5px); /* Move the card up slightly on hover */
}

.card-started .card-title-number {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-started .card-title-number div {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.card-started .card-content-get h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.card-started .card-content-get p {
  font-size: 16px;
  color: #666;
}

    `}
    </style>
    <section className="main-section banner5">
     <h1 className="banner-title">Get started in 4 steps</h1>
      <div className="container">
        <div className="columns">
          <div className="column is-3-desktop is-6-tablet is-12-mobile">
            <div className="card-started">
              <div className="card-title-number">
                <div><span className="icon"><i className="mdi mdi-chip mdi-24px">1</i></span></div>
              </div>
              <div className="card-content-get">
                <h2>You will need a GPS Tracker</h2>
                <p>A GPS Tracker is needed to transmit the real-time location of the vehicle to the Z1 Tracking cloud server.</p>
              </div>
            </div>
          </div>
          <div className="column is-3-desktop is-6-tablet is-12-mobile">
            <div className="card-started">
              <div className="card-title-number">
                <div><span className="icon"><i className="mdi mdi-sim mdi-24px">2</i></span></div>
              </div>
              <div className="card-content-get">
                <h2>You will need a SIM card for tracking</h2>
                <p>The GPS Tracker needs internet connectivity to send the data over the GSM network,make the devices run smoothly.</p>
              </div>
            </div>
          </div>
          <div className="column is-3-desktop is-6-tablet is-12-mobile">
            <div className="card-started">
              <div className="card-title-number">
                <div><span className="icon"><i className="mdi mdi-tools mdi-24px">3</i></span></div>
              </div>
              <div className="card-content-get">
                <h2>Activate the GPS Tracker</h2>
                <p>Download Z1 Tracking App from Playstore/Appstore and signup and call our customer support team.</p>
              </div>
            </div>
          </div>
          <div className="column is-3-desktop is-6-tablet is-12-mobile">
            <div className="card-started">
              <div className="card-title-number">
                <div><span className="icon"><i className="mdi mdi-download-circle-outline mdi-24px">4</i></span></div>
              </div>
              <div className="card-content-get">
                <h2>Activate the GPS Tracker</h2>
                <p>Download Z1 tracking App from Playstore/Appstore and signup and call our customer support team.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default Banner5;
