import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Services from './pages/Services'
import ContactUs from './pages/ContactUs'
import Blog from './pages/Blog'
import Login from './pages/Login'
import Products from './pages/Products'
import Features from './pages/Features'
import ErrorPage from './pages/Error';
import Bikes from './pages/bike-gps-tracker';
import Bus from './pages/bus-gps-tracker'
import Car from './pages/car-gps-tracker'
import Fleet from './pages/fleet-tracking-system'
import EmployeeAttendence from './pages/online-attendance'
import SchoolBus from './pages/school-bus-tracking-system'
import Basic from './pages/z1-tracker-basic'
import EOF from './pages/z1-tracker-engine-on-off'
import FA from './pages/z1-tracker-fuel-sensor'
import EA from './pages/z1-tracker-employee-attendence'
import Order from './pages/order'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/features" element={<Features/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/Blog" element={<Blog/>}/>
          <Route path="/products" element={<Products/>}/>
          <Route path="/contactus" element={<ContactUs/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/z1-tracker-basic" element={<Basic/>}/>
          <Route path="/z1-tracker-engine-on-off" element={<EOF/>}/>
          <Route path="/z1-tracker-fuel-sensor" element={<FA/>}/>
          <Route path="/z1-tracker-employee-attendence" element={<EA/>}/>
          <Route path="/bike-gps-tracker" element={<Bikes/>} />
          <Route path="/bus-gps-tracker" element={<Bus/>} />
          <Route path="/car-gps-tracker" element={<Car/>} />
          <Route path="/fleet-tracking-system" element={<Fleet/>} />
          <Route path="/online-attendance" element={<EmployeeAttendence/>} />
          <Route path="/school-bus-tracking-system" element={<SchoolBus/>} />
          <Route path="/order" element={<Order/>} />
          <Route path="/*" element ={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App