// import React from 'react'
// import './navbar.css';
// import { CiFacebook } from "react-icons/ci";
// import { CiTwitter } from "react-icons/ci";
// import { CiLinkedin } from "react-icons/ci";
// import { CiInstagram } from "react-icons/ci";

// const Footer = () => {
//   return (
//     <div>
//       <footer class="footer">
//         <div class="waves">
//           <div class="wave" id="wave1"></div>
//           <div class="wave" id="wave2"></div>
//           <div class="wave" id="wave3"></div>
//           <div class="wave" id="wave4"></div>
//         </div>
//         <ul class="social-icon">
//           <li class="social-icon__item"><a class="social-icon__link" href="https://www.facebook.com/happy.mishra.923">
//              <CiFacebook/>
//             </a></li>
//           <li class="social-icon__item"><a class="social-icon__link" href="https://twitter.com/AnkitMi45738043">
//               <CiTwitter/>
//             </a></li>
//           <li class="social-icon__item"><a class="social-icon__link" href="https://www.linkedin.com/in/ankit-mishra-64413324a/">
//              <CiLinkedin/>
//             </a></li>
//           <li class="social-icon__item"><a class="social-icon__link" href="https://www.instagram.com/happy98471_04/">
//               <CiInstagram/>
//             </a></li>
//         </ul>
//         <ul class="menu">
//           <li class="menu__item"><a class="menu__link" href="#">Home</a></li>
//           <li class="menu__item"><a class="menu__link" href="#">About</a></li>
//           <li class="menu__item"><a class="menu__link" href="#">Services</a></li>
//           <li class="menu__item"><a class="menu__link" href="#">Team</a></li>
//           <li class="menu__item"><a class="menu__link" href="#">Contact</a></li>
    
//         </ul>
//         <p>&copy;2024 CopyRight| All Rights Reserved</p>
//       </footer>
//       <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
//       <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
// </div>
//   )
// }

// export default Footer

import React from 'react';
import { CiFacebook } from "react-icons/ci";
import { CiTwitter } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { CiInstagram } from "react-icons/ci";

import './navbar.css';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="waves">
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
          <div className="wave" id="wave4"></div>
        </div>
        <ul className="social-icon">
          <li className="social-icon__item"><a className="social-icon__link" href="https://www.facebook.com/happy.mishra.923"><CiFacebook/></a></li>
          <li className="social-icon__item"><a className="social-icon__link" href="https://twitter.com/AnkitMi45738043"><CiTwitter/></a></li>
          <li className="social-icon__item"><a className="social-icon__link" href="https://www.linkedin.com/in/ankit-mishra-64413324a/"><CiLinkedin/></a></li>
          <li className="social-icon__item"><a className="social-icon__link" href="https://www.instagram.com/happy98471_04/"><CiInstagram/></a></li>
        </ul>
        <ul className="menu">
          <li className="menu__item"><a className="menu__link" href="#">Home</a></li>
          <li className="menu__item"><a className="menu__link" href="#">About</a></li>
          <li className="menu__item"><a className="menu__link" href="#">Services</a></li>
          <li className="menu__item"><a className="menu__link" href="#">Team</a></li>
          <li className="menu__item"><a className="menu__link" href="#">Contact</a></li>
        </ul>
        <div className="store-links">
          <a href="https://play.google.com/store/apps/details?id=com.zonetech.z1tracker&pli=1" target="_blank" rel="noopener noreferrer">
            <img src="appStore.png" alt="App Stor" className="store-icon" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.zonetech.z1tracker&pli=1" target="_blank" rel="noopener noreferrer">
            <img src="playStore.png" alt="Google Play Store" className="store-icon" />
          </a>
        </div>
        <p>&copy;2024 CopyRight| All Rights Reserved</p>
      </footer>
    </div>
  );
};

export default Footer;
