import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ContactUs = () => {
  return (
    <>
    <style>{`
    .contact-container {
  padding: 2rem 0 2rem 0;
  text-align: center;

}

.common-heading {
  font-size: 2rem;
  margin-bottom: 2rem;
  
}

.container {
  margin-top: 6rem;

}

.contact-form {
  max-width: 50rem;
  margin: auto;
}

.contact-inputs {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.contact-inputs input,
.contact-inputs textarea {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-inputs input[type="submit"] {
  cursor: pointer;
  transition: all 0.2s;
  background-color: #007bff;
  color: #fff;
  border: none;
}

.contact-inputs input[type="submit"]:hover {
  background-color: #0056b3;
}

    `}</style>
    <Navbar/>
    <div style={{paddingTop:'60px'}}>
    <div className="contact-container">
      <h2 className="common-heading">Contact Us</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.4825178920287!2d83.44892694114931!3d27.508006254787666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39969b521428599f%3A0xbd5c8fdd63570c2a!2sZ1%20Technology%20%26%20Trade%20Pvt.Ltd!5e0!3m2!1sen!2snp!4v1712432340976!5m2!1sen!2sn"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="container">
        <div className="contact-form">
          <form action="https://formspree.io/f/mpzgbbgg" method="POST" className="contact-inputs">
            <input type="text" placeholder="Username" name="username" required autoComplete="off" />

            <input type="email" name="Email" placeholder="Email" autoComplete="off" required />

            <textarea
              name="Message"
              cols="30"
              rows="10"
              required
              placeholder="Enter your message"
              autoComplete="off"
            ></textarea>

            <input type="submit" value="Send" />
          </form>
        </div>
      </div>
    </div>
    </div>
    <Footer/>
    </>
  );
};

export default ContactUs;