import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const Products = () => {
  return (
    <>
    <Navbar/>
    <div className='body'>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '20px', color: '#333' }}>Welcome to Our own Devices branding Page</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ flex: 1, textAlign: 'left' }}>
          <h1>CEO Voice</h1>
          <h2>Soft.Er. Bhanu Marasini</h2>
          <p>
          Vehicle GPS Tracking system, 
          is a pivotal tool in modern fleet management. It ensures precise monitoring and management of vehicles, 
          optimizing efficiency and enhancing overall operational control. With its capability to 
          provide real-time insights and historical data analysis, our GPS tracking system empowers businesses to 
          make informed decisions, streamline processes, and maximize productivity."
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <img src="/device.jpeg" alt="devices" />
        </div>
      </div>
      <div style={{ marginTop: '50px', textAlign: 'left' }}>
        <h2>Contact Us</h2>
        <p>Email: bhanu@ceo.com</p>
        <p>Phone: +977 9857050123</p>
        <p>Address: Zonestreet, Bahirahwa, Nepal</p>
      </div>
    </div>
    <Footer/>
</>
  );
}

export default Products;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const Products = () => {
//   const [devices, setDevices] = useState([]);

//   useEffect(() => {
//     const fetchDevices = async () => {
//       try {
//         const response = await axios.get('http://103.90.86.173:8082/api/devices', {
//           headers: {
//             Authorization: `Bearer RzBFAiEA4Q6Y0cRFpYsLysrxflHZmmVxCLsw4w9nmEK1YRcqoE0CICVpohzuWtBLZsbbGK53-JBQV9-ua4T-ScBRDu3M5tDQeyJ1IjoxLCJlIjoiMjAyNC0wNC0wMVQxODoxNTowMC4wMDArMDA6MDAifQ` // Replace 'your_token' with the actual token
//           }
//         });

//         if (response.status === 200) {
//           setDevices(response.data);
//         }
//       } catch (error) {
//         console.error('Error fetching devices:', error);
//       }
//     };

//     fetchDevices();
//   }, []);

//   return (
//     <div className="devices-container">
//       <h1>List of Devices</h1>
//       <ul className="device-list">
//         {devices.map((device) => (
//           <li key={device.id} className="device-item">
//             {device.name} - {device.uniqueId}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Products;
