// import styled from "styled-components";
// import { Button } from "./styles/Button";
import { NavLink } from "react-router-dom";
import React from "react";

const ErrorPage = () => {
    return (
        <>
        <style>{`
        .container {
    padding: 9rem 0;
    text-align: center;

    h2 {
      font-size: 10rem;
    }

    h3 {
      font-size: 4.2rem;
    }

    p {
      margin: 2rem 0;
    }
  }
        `}</style>
         <div> 
            <div className="container">
                <div>
                <h2>404</h2>
                <h3>UH OH! You're lost.</h3>
                <p>
                    The Page you are looking for doesn't exist. How you got here is a mystery.
                    But you can click the button below to go to back to the homepage.
                </p>
               <NavLink to="/"> 
                </NavLink>
                </div>
            </div>
         </div>
         </>
    );
};

export default ErrorPage;