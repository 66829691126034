


import React from 'react';
import { MdPhoneAndroid } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import { BsBuildingsFill } from "react-icons/bs";
import { HiMiniUserGroup } from "react-icons/hi2";
import { MdOnlinePrediction } from "react-icons/md";
import { BiUserCheck } from "react-icons/bi";
import './navbar.css';

const Banner4 = () => {
  return (
    <>
    <style>
      {`
      .banner-container {
  display: flex;
  overflow: hidden;
  
}

.column {
  flex: 1;
}

.banner-image {
  width: 50%; /* Adjust this value as needed */
}

      `}
    </style>
    <div className="banner-container">
      <div className="column is-7-desktop is-6-tablet is-12-mobile">
        <header>
          <h1 className='headingone'>Why Choose Z1 Tracking GPS?</h1>
        </header>
        <div className="section-content">
          <p className='headingparagraph'>
            As a leading GPS tracking company in Nepal, Z1 Tracking GPS holds
            the strongest position amongst similar companies. Since our
            launch in 2024, we have always focused on providing excellent
            Customer Service and upgrading various Products Features.
          </p>
        </div>
        <div className="achivements-list">
          <div>
            <span className="icon is-medium">
              <MdPhoneAndroid />
            </span>
            <h2>1000+</h2>
            <p>App Downloads</p>
          </div>
          <div>
            <span className="icon is-medium">
              <FaBuilding/>
            </span>
            <h2>100+</h2>
            <p>Small Businesses</p>
          </div>
          <div>
            <span className="icon is-medium">
              <i><BsBuildingsFill/></i>
            </span>
            <h2>20+</h2>
            <p>B2B Business</p>
          </div>
          <div>
            <span className="icon is-medium">
              <HiMiniUserGroup/>
            </span>
            <h2>10+</h2>
            <p>National Wide Dealers</p>
          </div>
          <div>
            <span className="icon is-medium">
              <MdOnlinePrediction/>
            </span>
            <h2>5000+</h2>
            <p>Devices Online</p>
          </div>
          <div>
            <span className="icon is-medium">
              <BiUserCheck/>
            </span>
            <h2>1000+</h2>
            <p>Daily Active Users</p>
          </div>
        </div>
      </div>
      <div className="banner-image">
        <img src="bannerimages.jpg" alt="Banner" />
      </div>
    </div>
    </>
  );
};

export default Banner4;
