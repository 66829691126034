import React from 'react';

const Banner8 = () => {
  return (
  <>
  <style>{`
  .main-section {
          padding: 50px 0; /* Adjust the padding as needed */
        }

        .container-banner8 {
          max-width: 1200px; /* Adjust the maximum width as needed */
          margin: 0 auto;
        }

        header h1 {
          text-align: center;
        }

        .column-banner8 {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .column-banner8 {
          flex: 0 0 calc(33.333% - 30px); /* Adjust column width and spacing as needed */
          padding: 0 15px; /* Adjust the column padding as needed */
        }

        .image-card {
          display: block;
          position: relative;
          text-align: center;
          text-decoration: none;
          color: #fff; /* Text color for the heading */
          border-radius: 5px; /* Adjust border radius as needed */
          overflow: hidden;
          margin-bottom: 20px; /* Adjust margin bottom as needed */
          transition: transform 0.3s ease; /* Adding transition for smoother hover effect */
        }

        .image-card:hover {
          transform: scale(1.05); /* Scale up the image on hover */
        }

        .image-card img {
          width: 100%;
          height: auto;
          display: block;
        }

        .image-card h2 {
          position: absolute;
          bottom: 20px; /* Adjust the distance from the bottom as needed */
          left: 0;
          right: 0;
          margin: 0;
          padding: 0 15px; /* Adjust padding as needed */
          font-size: 20px; /* Adjust font size as needed */
          text-align: center;
        }

        /* Media query for responsiveness */
        @media (max-width: 1024px) {
          .column {
            flex: 0 0 calc(50% - 30px); /* Make columns 50% width on smaller screens */
          }
        }

        @media (max-width: 768px) {
          .column {
            flex: 0 0 100%; /* Make columns full width on even smaller screens */
          }
        }


  `}</style>
    <section className="main-section industries">
      <div className="container-banner8">
        <header>
          <h1>Industries We Cover</h1>
        </header>
        <div className="column-banner8">
          <div className="column-banner8 is-4-desktop is-6-tablet is-12-mobile">
            <a href="https://tracker-gps.com/vehicle/fleet-tracking-system" className="image-card banner8" style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://cms.tracker-gps.com/media/industries/fleet-vehicle-tracking.webp")'}}>
            <img loading="lazy" src="fleets.webp" alt="Fleet Vehicle Tracking" />
              <h2>Fleet Vehicle Tracking</h2>
            </a>
          </div>
          <div className="column-banner8 is-4-desktop is-6-tablet is-12-mobile">
          <a href="#" className="image-card banner8" style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://cms.tracker-gps.com/media/industries/logistics-tracking.webp")'}}>
            <img loading="lazy" src="Assets.jpg" alt="Fleet Vehicle Tracking" />
              <h2>Asset Tracking</h2>
            </a>
          </div>
          <div className="column-banner8 is-4-desktop is-6-tablet is-12-mobile">
            <a href="https://tracker-gps.com/vehicle/bus-gps-tracker" className="image-card banner8" style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://cms.tracker-gps.com/media/industries/public-bus-tracking.webp")'}}>
            <img loading="lazy" src="publictransport.jpg" alt="Fleet Vehicle Tracking" />
              <h2>Public Transport</h2>
            </a>
          </div>
          <div className="column-banner8 is-4-desktop is-6-tablet is-12-mobile">
            <a href="https://tracker-gps.com/vehicle/school-bus-tracking-system" className="image-card banner8" style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://cms.tracker-gps.com/media/industries/school-bus-tracking.webp")'}}>
            <img loading="lazy" src="schoolbussss.jpg" alt="Fleet Vehicle Tracking" />
              <h2>School Bus</h2>
            </a>
          </div>
          <div className="column-banner8 is-4-desktop is-6-tablet is-12-mobile">
            <a href="#" className="image-card banner8" style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://cms.tracker-gps.com/media/industries/ambulance-tracking.webp")'}}>
            <img loading="lazy" src="Ambulances.jpg" alt="Fleet Vehicle Tracking" />
              <h2>Ambulance</h2>
            </a>
          </div>
          <div className="column-banner8 is-4-desktop is-6-tablet is-12-mobile">
            <a href="https://tracker-gps.com/vehicle/bike-gps-tracker" className="image-card banner8" style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://cms.tracker-gps.com/media/industries/moorcycle-tracking.webp")'}}>
            <img loading="lazy" src="motorbike.jpg" alt="Fleet Vehicle Tracking" />
              <h2>Motorcycles Tracking</h2>
            </a>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default Banner8;

