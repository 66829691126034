import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { PiPhoneDisconnectDuotone } from "react-icons/pi";
import contactIcon from '../contacticon.jpg';
import './navbar.css';

const Banner = () => {
  const [showContactIcons, setShowContactIcons] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const toggleContactIcons = () => {
    setShowContactIcons(!showContactIcons);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(currentSlide === 0 ? 1 : 0); 
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div style={{paddingTop:'100px'}}>
      <div className="image-below-navbar">
        <img src={currentSlide === 0 ? "bikebanner.jpg" : "schoolbanner.jpg"} alt="Image Below Navbar" />
      </div>
      <div className="contact-icon-container">
        <div className="contact-icons" style={{ display: showContactIcons ? 'flex' : 'none' }}>
          <FaWhatsapp className="whatsapp-icon" style={{ color: '#25D366', fontSize: '30px' }} />
          <PiPhoneDisconnectDuotone className="telephone-icon" style={{ color: '#f0f0f0', fontSize: '30px' }} />
        </div>
        <NavLink to="/contactus" className="contact-link" onClick={toggleContactIcons}>
          <img src={contactIcon} alt="Contact Icon" />
        </NavLink>
      </div>
      <div className='header'>
        <h1 className='headingone'>Who we are</h1>
        <h6>GPSTrack is a leader in vehicle tracking industry, providing open platform fleet management solutions to businesses of all sizes.</h6>
        <p className='headingparagraph'>GPSTrack intuitive, full-featured solutions help businesses better manage their drivers and vehicles by extracting accurate and actionable intelligence from real-time and historical trips data.</p>
      </div>
      <div className='header'>
        <h1>Our Advantages</h1>
        <div className="advantages-container">
          <div className="advantage">
            <img src="advantages1.png" alt="Advantage 1" />
            <p>100% Confidence & Quality</p>
            <div className='paragraph'>We offer Certified Products. Your devices arrive fully tested, approved & activated.</div>
          </div>
          <div className="advantage">
            <img src="advantages2.png" alt="Advantage 2" />
            <p>Fully Optimized</p>
            <div className='paragraph'>We take care of full control of your fleet with practical, effective at a low cost.</div>
          </div>
          <div className="advantage">
            <img src="advantages3.png" alt="Advantage 3" />
            <p>Customer Satisfaction</p>
            <div className='paragraph'>With our professional support you can find a GPS Vehicle Tracking Solution that matches your business needs.</div>
          </div>
          <div className="advantage">
            <img src="advantages4.png" alt="Advantage 4" />
            <p>Best Pricing</p>
            <div className='paragraph'>We offer you a free hardware at Rs 5500 per month hardware tracker. 30 days money back guarantee</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;

