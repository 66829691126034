import React from 'react';

const Banner7 = () => {
  return (
    <>
      <style>{`
      .main-section.shop-now {
  background-color: #fff;
  padding: 40px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  overflow:hidden;
}

header {
  text-align: center;
  margin-bottom: 20px;
}

h1 {
  font-size: 32px;
}

.section-subtitle {
  text-align: center;
  margin-bottom: 20px;
}

.shop-now-content {
  margin-top: 20px;
}

.carousel-list.products {
  position: relative;
}

.carousel-slides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.carousel-slide {
  flex: 0 0 auto;
  scroll-snap-align: start;
}

.shop-card {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease; /* Adding transition for smoother hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow effect */
}

.shop-card:hover {
  transform: scale(1.05); /* Scale up the card on hover */
}

.shop-card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.shop-card h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.shop-card p {
  font-size: 16px;
  margin: 0;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.carousel-arrow .icon {
  cursor: pointer;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin: 0 5px;
}

.carousel-arrow .icon i {
  color: #fff;
}

      `}</style>
      <div style={{paddingTop:'60px'}}>
      <section className="main-section shop-now">
        <div className="containeries">
          <header>
            <h1>Shop Now and Order for Installation</h1>
          </header>
          <div className="section-subtitle">
            <p className='headingparagraph'>
              Z1 Tracker GPS devices and services have very flexible and competitive
              pricing from where you can easily select your best option according
              to your needs.
            </p>
          </div>
          {/* <div className="shop-now-content">
            <div className="carousel-list products">
              <div className="carousel-slides">
                <div className="carousel-slide is-active">
                  <div className="shop-card">
                    <img src="device.jpeg" alt="shop now" />
                    <h2>Z1 Tracking System</h2>
                    <p>
                      <span>Rs. 9000.00</span>
                      Rs. 7200.00
                    </p>
                  </div>
                </div> */}
                <div className="carousel-slide">
                  <div className="shop-card">
                    <img src="device-removebg.png" alt="shop now" />
                    <h2>Z1 Tracking System</h2>
                    <p>
                      <span>Rs. 10000.00</span>
                      Rs. 9000.00
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-arrow">
                <span className="icon has-icons-left"><i className="mdi mdi-chevron-left mdi-24px"></i></span>
                <span className="icon has-icons-right"><i className="mdi mdi-chevron-right mdi-24px"></i></span>
              </div>
            {/* </div> */}
          {/* </div>
        </div> */}
      </section>
      </div>
    </>
  );
}

export default Banner7;
