import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Banner4 from '../components/Banner4';
import Banner5 from '../components/Banner5';
// import Banner6 from '../components/Banner6';
import Banner7 from '../components/Banner7';
import Banner8 from '../components/Banner8';
import Banner2 from '../components/Banner2';

const Features = () => {
  return (
    <>
    <Navbar/>
    <Banner2/>
    <Banner4/>
    <Banner5/>
    {/* <Banner6/> */}
    <Banner7/>
    <Banner8/>
    <Footer/>
</>
  );
}

export default Features;
