import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Basic = () => {
  return (
    <>
      <style>{`
        body {
          font-family: Arial, sans-serif;
          background-color: #f3f3f3;
        }
        .container {
          max-width: 800px;
          margin: 20px auto;
          padding: 20px;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1, h2, h3 {
          color: #333;
        }
        p {
          line-height: 1.6;
          color: #555;
        }
        ul {
          list-style-type: disc;
          color: #555;
        }
        .banner-img {
          width: 100%;
          border-radius: 10px;
          margin-bottom: 20px;
        }
        .section-heading {
          color: #333;
          font-size: 24px;
          margin-top: 20px;
        }
        .highlight {
          color: #007bff;
          font-weight: bold;
        }
        .emphasis {
          font-style: italic;
          color: #555;
        }
      `}</style>
      <Navbar />
      <div style={{ paddingTop: '100px' }}>
        <div className="container">
          <h1>Z1 Tracker: Revolutionizing Vehicle Tracking</h1>
          {/* <img src="z1-tracker-banner.jpg" alt="Z1 Tracker Banner" className="banner-img"> */}
          <p>In today's fast-paced world, managing a fleet of vehicles efficiently is crucial for businesses of all sizes. <span className="highlight">Z1 Tracker</span> offers a cutting-edge solution for vehicle tracking, empowering businesses with real-time insights and control over their assets.</p>

          <h2 className="section-heading">How Z1 Tracker Works</h2>
          <p><span className="highlight">Z1 Tracker</span> utilizes advanced GPS technology combined with robust software to provide accurate and reliable vehicle tracking. Each vehicle is equipped with a <span className="highlight">Z1 Tracker</span> device that communicates with satellites to determine its precise location.</p>

          <h2 className="section-heading">Key Features and Benefits</h2>
          <p>With <span className="highlight">Z1 Tracker</span>, businesses can enjoy a wide range of features and benefits:</p>
          <ul>
            <li><span className="highlight">Real-Time Tracking:</span> Monitor your vehicles' locations in real-time, enabling you to optimize routes and respond promptly to any issues or emergencies.</li>
            <li><span className="highlight">Geofencing:</span> Define custom geofences and receive instant notifications when a vehicle enters or leaves a designated area, enhancing security and efficiency.</li>
            <li><span className="highlight">Driver Behavior Monitoring:</span> Track driver behavior such as speeding, harsh braking, and idle time, promoting safer driving habits and reducing fuel costs.</li>
            <li><span className="highlight">Historical Data Analysis:</span> Access detailed historical trip data to analyze trends, identify areas for improvement, and make informed business decisions.</li>
            <li><span className="highlight">Integration:</span> Seamlessly integrate <span className="highlight">Z1 Tracker</span> with your existing fleet management systems or third-party applications for enhanced functionality and convenience.</li>
          </ul>

          <h2 className="section-heading">Why Choose Z1 Tracker?</h2>
          <p><span className="highlight">Z1 Tracker</span> stands out from the competition due to its reliability, versatility, and user-friendly interface. Whether you operate a small delivery service or manage a large transportation fleet, <span className="highlight">Z1 Tracker</span> offers the tools and support you need to streamline operations, reduce costs, and enhance overall efficiency.</p>

          <h2 className="section-heading">Get Started Today</h2>
          <p>Experience the power of <span className="highlight">Z1 Tracker</span> and take your vehicle tracking capabilities to the next level. Contact us today to learn more and schedule a demo.</p>

          <p className="emphasis">Transform your fleet management with <span className="highlight">Z1 Tracker</span>!</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Basic;
