// import React from 'react';

// const Banner9 = () => {
//   return (
//     <>
//     <style>
//         {`

// .main-section-banner9 {
//   background-color: #f8f8f8;
//   padding: 50px 0;
// }

// .container-banner9 {
//   max-width: 1200px;
//   margin: 0 auto;
//   padding: 0 15px;
// }

// .callback {
//   display: flex;
//   align-items: center;
// }

// .callback-left {
//   flex: 0 0 50%;
// }

// .callback-left img {
//   width: 100%;
//   height: auto;
// }

// .callback-right {
//   flex: 0 0 50%;
//   padding-left: 50px;
// }

// h1 {
//   font-size: 32px;
//   margin-bottom: 20px;
//   color: #333;
// }

// .form-wrapper {
//   transition: transform 0.3s ease-in-out;
// }

// .field {
//   margin-bottom: 20px;
// }

// .input, .textarea {
//   width: 100%;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
// }

// .button {
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background-color 0.3s ease-in-out;
// }

// .button:hover {
//   background-color: #0056b3;
// }

// /* Animation */
// .form-wrapper {
//   transform: translateY(50px);
//   opacity: 0;
// }

// .animate {
//   transform: translateY(0);
//   opacity: 1;
// }

//         `}
//     </style>
//     <section className="main-section-banner9">
//       <div className="container-banner9">
//         <div className="callback">
//           <div className="callback-left">
//             <img loading="lazy" src="/about.avif" alt="contact" />
//           </div>
//           <div className="callback-right">
//             <h1>Request a Callback</h1>
//             <form>
//               <div className="field">
//                 <label className="label">Full Name</label>
//                 <div className="control is-clearfix">
//                   <input type="text" autocomplete="on" placeholder="Enter Full Name" className="input" />
//                 </div>
//               </div>
//               <div className="field">
//                 <div className="field-body">
//                   <div className="field is-grouped">
//                     <div className="field is-expanded">
//                       <label className="label">Phone Number</label>
//                       <div className="control is-clearfix">
//                         <input type="number" autocomplete="on" placeholder="Enter Phone Number" className="input" />
//                       </div>
//                     </div>
//                     <div className="field is-expanded">
//                       <label className="label">Email Address</label>
//                       <div className="control is-clearfix">
//                         <input type="email" autocomplete="on" placeholder="Enter Email Address" className="input" />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="field text-position">
//                 <label className="label">Message</label>
//                 <div className="control is-clearfix">
//                   <textarea maxlength="200" placeholder="Your Message here..." className="textarea"></textarea>
//                   <small className="help counter is-invisible"> 0 / 200 </small>
//                 </div>
//               </div>
//             </form>
//             <button type="button" className="button is-primary">
//               <span>Send</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </section>
//     </>
//   );
// }

// export default Banner9;


// import React, { useState } from 'react';

// const Banner9 = () => {
//   const [message, setMessage] = useState('');
  
//   // Function to handle textarea change event
//   const handleMessageChange = (e) => {
//     const text = e.target.value;
//     setMessage(text);
//   }

//   // Function to calculate the remaining characters
//   const remainingCharacters = 200 - message.length;

//   return (
//     <>
//     <style>
//         {`
//         .main-section-banner9 {
//   background-color: #f8f8f8;
//   padding: 50px 0;
// }

// .container-banner9 {
//   max-width: 1200px;
//   margin: 0 auto;
//   padding: 0 15px;
// }

// .callback {
//   display: flex;
//   align-items: center;
// }

// .callback-left {
//   flex: 0 0 50%;
// }

// .callback-left img {
//   width: 100%;
//   height: auto;
// }

// .callback-right {
//   flex: 0 0 50%;
//   padding-left: 50px;
// }

// h1 {
//   font-size: 32px;
//   margin-bottom: 20px;
//   color: #333;
// }

// .form-wrapper {
//   transition: transform 0.3s ease-in-out;
// }

// .field {
//   margin-bottom: 20px;
// }

// .input, .textarea {
//   width: 100%;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
// }

// .button {
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background-color 0.3s ease-in-out;
// }

// .button:hover {
//   background-color: #0056b3;
// }

// /* Animation */
// .form-wrapper {
//   transform: translateY(50px);
//   opacity: 0;
// }

// .animate {
//   transform: translateY(0);
//   opacity: 1;
// }
//         `}
//     </style>
//     <section className="main-section-banner9">
//       <div className="container-banner9">
//         <div className="callback">
//           <div className="callback-left">
//             <img loading="lazy" src="/about.avif" alt="contact" />
//           </div>
//           <div className="callback-right">
//             <h1>Request a Callback</h1>
//             <form>
//               <div className="field">
//                 <label className="label">Full Name</label>
//                 <div className="control is-clearfix">
//                   <input type="text" autoComplete="on" placeholder="Enter Full Name" className="input" />
//                 </div>
//               </div>
//               <div className="field">
//                 <div className="field-body">
//                   <div className="field is-grouped">
//                     <div className="field is-expanded">
//                       <label className="label">Phone Number</label>
//                       <div className="control is-clearfix">
//                         <input type="number" autoComplete="on" placeholder="Enter Phone Number" className="input" />
//                       </div>
//                     </div>
//                     <div className="field is-expanded">
//                       <label className="label">Email Address</label>
//                       <div className="control is-clearfix">
//                         <input type="email" autoComplete="on" placeholder="Enter Email Address" className="input" />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="field text-position">
//                 <label className="label">Message</label>
//                 <div className="control is-clearfix">
//                   <textarea maxLength="200" placeholder="Your Message here..." className="textarea" value={message} onChange={handleMessageChange}></textarea>
//                   <small className="help counter">{remainingCharacters} / 200</small>
//                 </div>
//               </div>
//             </form>
//             <button type="button" className="button is-primary">
//               <span>Send</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </section>
//     </>
//   );
// }

// export default Banner9;


import React, { useState } from 'react';

const Banner9 = () => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!fullName.trim()) {
      errors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
      isValid = false;
    }

    if (!email.trim()) {
      errors.email = "Email Address is required";
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = "Invalid Email Address";
      isValid = false;
    }

    if (!message.trim()) {
      errors.message = "Message is required";
      isValid = false;
    } else if (message.length > 200) {
      errors.message = "Message should not exceed 200 characters";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Perform form submission
      console.log("Form submitted successfully!");
    } else {
      console.error("Form validation failed.");
    }
  };

  return (
    <>
     <style>
        {`
        .main-section-banner9 {
  background-color: #f8f8f8;
  padding: 50px 0;
}

.container-banner9 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.callback {
  display: flex;
  align-items: center;
}

.callback-left {
  flex: 0 0 50%;
}

.callback-left img {
  width: 100%;
  height: auto;
}

.callback-right {
  flex: 0 0 50%;
  padding-left: 50px;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
}

.form-wrapper {
  transition: transform 0.3s ease-in-out;
}

.field {
  margin-bottom: 20px;
}

.input, .textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button:hover {
  background-color: #0056b3;
}

/* Animation */
.form-wrapper {
  transform: translateY(50px);
  opacity: 0;
}

.animate {
  transform: translateY(0);
  opacity: 1;
}
        `}
    </style>
      <section className="main-section-banner9">
        <div className="container-banner9">
          <div className="callback">
            <div className="callback-left">
              <img loading="lazy" src="/about.avif" alt="contact" />
            </div>
            <div className="callback-right">
              <h1>Request a Callback</h1>
              <form onSubmit={handleFormSubmit}>
                <div className="field">
                  <label className="label">Full Name</label>
                  <div className="control is-clearfix">
                    <input
                      type="text"
                      autoComplete="on"
                      placeholder="Enter Full Name"
                      className={`input ${errors.fullName && 'is-danger'}`}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    {errors.fullName && <p className="help is-danger">{errors.fullName}</p>}
                  </div>
                </div>
                <div className="field">
                  <div className="field-body">
                    <div className="field is-grouped">
                      <div className="field is-expanded">
                        <label className="label">Phone Number</label>
                        <div className="control is-clearfix">
                          <input
                            type="text"
                            autoComplete="on"
                            placeholder="Enter Phone Number"
                            className={`input ${errors.phoneNumber && 'is-danger'}`}
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                          {errors.phoneNumber && <p className="help is-danger">{errors.phoneNumber}</p>}
                        </div>
                      </div>
                      <div className="field is-expanded">
                        <label className="label">Email Address</label>
                        <div className="control is-clearfix">
                          <input
                            type="email"
                            autoComplete="on"
                            placeholder="Enter Email Address"
                            className={`input ${errors.email && 'is-danger'}`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && <p className="help is-danger">{errors.email}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field text-position">
                  <label className="label">Message</label>
                  <div className="control is-clearfix">
                    <textarea
                      maxLength="200"
                      placeholder="Your Message here..."
                      className={`textarea ${errors.message && 'is-danger'}`}
                      value={message}
                      onChange={handleMessageChange}
                    />
                    <small className="help counter">{message.length} / 200</small>
                    {errors.message && <p className="help is-danger">{errors.message}</p>}
                  </div>
                </div>
                <button type="submit" className="button is-primary">
                  <span>Send</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner9;

