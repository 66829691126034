// import React from 'react'

// const Banner2 = () => {
//   return (
//     <div>
//     <div className='header'>
//     <div className="advantages-container">
//       <div className="advantages">
//         <img src="home1.jpg" alt="Advantage 1" />
//         <div className='paragraphs'>Feel tracking and management software</div>
//       </div>
//       <div className="advantages">
//         <img src="home2.jpg" alt="Advantage 2" />
//         <div className='paragraphs'>Field services & scheduling management software</div>
//       </div>
//       <div className="advantages">
//         <img src="home3.jpg" alt="Advantage 3" />
//         <div className='paragraphs'>ELD compilance management solution</div>
//       </div>
//     </div>
//   </div></div>
//   )
// }

// export default Banner2


// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import './navbar.css';

// const Banner2 = () => {
//   return (
//     <div>
//       <div className='header'>
//         <div className="advantages-container">
//           <div className="advantages">
//             <img src="home1.jpg" alt="Advantage 1" />
//             <div className='paragraphs'>Feel tracking and management software</div>
//             <NavLink to="/advantage1" className="read-more-button">READ MORE</NavLink>
//           </div>
//           <div className="advantages">
//             <img src="home2.jpg" alt="Advantage 2" />
//             <div className='paragraphs'>Field services & scheduling management software</div>
//             <NavLink to="/advantage2" className="read-more-button">READ MORE</NavLink>
//           </div>
//           <div className="advantages">
//             <img src="home3.jpg" alt="Advantage 3" />
//             <div className='paragraphs'>ELD compliance management solution</div>
//             <NavLink to="/advantage3" className="read-more-button">READ MORE</NavLink>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Banner2;

import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css';


const Banner2 = () => {
  const imageRef1 = useRef(null);
  const imageRef2 = useRef(null);
  const imageRef3 = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const images = [imageRef1.current, imageRef2.current, imageRef3.current];

      images.forEach((image) => {
        const topPosition = image.getBoundingClientRect().top;
        const bottomPosition = image.getBoundingClientRect().bottom;

        const windowHeight = window.innerHeight;

        if (topPosition < windowHeight && bottomPosition > 0) {
          image.classList.add('animate');
        } else {
          image.classList.remove('animate');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <style>{`
    
.advantages-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
}

.advantage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advantages {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
}

.advantages.animate {
  opacity: 1;
  transform: translateY(0);
}

.advantage img {
  width: 100px;
  height: auto;
}

.advantage p {
  color: #000000;
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
}

/* Define keyframes for the animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to the h1 element */
.header h1 {
  animation: fadeInUp 0.5s ease forwards;
}

.paragraph {
  color: #4b4747;
  margin-top: 5px;
  font-size: 13px;
  text-align: center;
}

.paragraphs {
  color: #000000;
  margin-top: 10px;
  font-size: 30px;
  text-align: center;
}

/* Add this CSS to your existing stylesheet or create a new one */

/* Style for the "READ MORE" button */
.read-more-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Blue color, you can change it to suit your design */
  color: #fff; /* White text color */
  text-decoration: none; /* Remove underline */
  border-radius: 5px; /* Rounded corners */
  margin-top: 10px; /* Add some top margin */
  transition: background-color 0.3s; /* Smooth transition effect */
  margin-left:150px
}

/* Hover effect for the "READ MORE" button */
.read-more-button:hover {
  background-color: #0056b3; /* Darker shade of blue */
}

/* Adjustments for the paragraphs */
.paragraphs {
  margin-top: 10px; /* Add some top margin */
  color: #333; /* Text color */
}


/* navbar.css */

/* Other styles... */

.login-button {
  margin-left: 70px; /* Push the button to the right */
}

.login-link {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s;
}

.login-link:hover {
  background-color: #0056b3;
}
    `}</style>
    <div style={{paddingTop:"25px"}}>
      <div className='headers'>
        <div className="advantages-container">
          <div className="advantages" ref={imageRef1}>
            <img src="home1.jpg" alt="Advantage 1" />
            <div className='paragraphs'>Fleet tracking and management software</div>
            <NavLink to="/advantage1" className="read-more-button">READ MORE</NavLink>
          </div>
          <div className="advantages" ref={imageRef2}>
            <img src="home2.jpg" alt="Advantage 2" />
            <div className='paragraphs'>Field services & scheduling management software</div>
            <NavLink to="/advantage2" className="read-more-button">READ MORE</NavLink>
          </div>
          <div className="advantages" ref={imageRef3}>
            <img src="home3.jpg" alt="Advantage 3" />
            <div className='paragraphs'>ELD compliance management solution</div>
            <NavLink to="/advantage3" className="read-more-button">READ MORE</NavLink>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Banner2;

