import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { IoMenuSharp, IoCloseSharp } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== hasScrolled) {
        setHasScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  return (
    <>
      <style>{`.navy-blue-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: navy;
  color: white;
  padding: 10px 20px;
}


        .navbar-container {
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 1000;
          background-color: #ffffff;
          transition: box-shadow 0.3s ease-in-out;
          box-shadow: ${hasScrolled ? '0 2px 4px rgba(0, 0, 0, 0.2)' : 'none'};
        }

        {/* .navbar-container {
          width: 100%;
          position: fixed;
          top: ${hasScrolled ? '60px' : '90px'};
          left: 0;
          z-index: 1000;
          background-color: #ffffff;
          transition: box-shadow 0.3s ease-in-out;
          box-shadow: ${hasScrolled ? '0 2px 4px rgba(0, 0, 0, 0.2)' : 'none'};
        } */}


        .container-main {
          max-width: 1100px;
          margin: 0 auto;
          padding: 0px 1px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 70px;
        }

        .logos {
          margin-right: 20px; /* Adjusted margin to shift logo */
          margin-top: 10px;
        }

        .logos img {
          max-width: 40vw;
          height: 12vh;
        }

        .nav-elements ul {
          display: flex;
          justify-content: space-between;
          list-style-type: none;
        }

        .nav-elements ul li:not(:last-child) {
          margin-right: 60px;

        }

        .nav-elements ul a {
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-decoration: none;;
        }

        .nav-elements ul a.active {
          color: #574c4c;
          font-weight: 500;
          position: relative;
        }

        .nav-elements ul a.active::after {
          content: '';
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #574c4c;
        }

        .mobile-menu {
          display: none;
          background-color: #ffffff;
          position: fixed;
          top: 60px;
          left: 0;
          width: 100%;
          height: calc(100vh - 60px);
          z-index: 999;
          overflow-y: auto;
          padding-top: 60px;
        }

        .mobile-menu.active {
          display: block;
        }

        .mobile-menu-overlay {
          position: fixed;
          top: 60px;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 998;
          display: none;
        }

        .mobile-menu-overlay.active {
          display: block;
        }

        .mobile-menu-content {
          padding: 20px;
        }

        .mobile-menu ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }

        .mobile-menu ul li {
          margin-bottom: 20px;
        }

        .mobile-menu ul li a {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
          text-decoration: none;
        }

        .mobile-menu-toggle {
          display: none;
          position: absolute;
          top: 20px;
          right: 20px;
          cursor: pointer;
          z-index: 1001;
        }

        @media screen and (max-width: 768px) {
          .mobile-menu-toggle {
            display: block;
          }

          .navbar {
            padding-top: 20px;
          }
        }

        .navbar-dropdown {
          display: none;
          position: absolute;
          background-color: #fff;
          min-width: 200px;
          z-index: 1;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        }

        .navbar-item.has-dropdown:hover .navbar-dropdown {
          display: block;
        }

        .navbar-dropdown a {
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          color: #000;
        }

        .navbar-dropdown a:hover {
          background-color: #f0f0f0;
        }

        .login-button {
          margin-right: 10px; /* Adjust margin-right to create a gap */
        }

        .order-button {
          white-space: nowrap;
          margin-left: 10px; /* Adjust margin-left to create a gap */
        }

        .contact-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          color: #000;
          padding: 0 60px;
          background-color: #f0f0f0;
        }

        @media screen and (max-width: 480px) {
          .contact-info {
            font-size: 12px;
          }
        }
      `}</style>
      <div className={`navbar-container ${hasScrolled ? 'shadow' : ''}`}>
      <div className="contact-info">
          <div>Buy GPS Tracker For Bike and Car Online . . !</div>
          <div> <FaWhatsapp style={{marginTop:"5px"}}/> 9857050123 <span style={{marginLeft:"50px"}}> 071-590132 <span style={{marginLeft:"50px"}}>9815483410</span> </span> </div>
        </div>
        <nav className="navbar">
          <div className="container-main">
            <div className="logos">
              <img src='logo2.png' alt='Logo'/>
            </div>
            <div className="nav-elements">
              <ul>
                <li>
                  <NavLink to="/" exact activeClassName="active" onClick={() => setIsOpen(false)}>Home</NavLink>
                </li>
                <li className="navbar-item has-dropdown">
                  <span className="navbar-link">Products</span>
                  <div className="navbar-dropdown">
                    <NavLink to="/z1-tracker-basic" activeClassName="active">Z1 Tracker Basic</NavLink>
                    <NavLink to="/z1-tracker-engine-on-off" activeClassName="active">Z1 Tracker EOF</NavLink>
                    <NavLink to="/z1-tracker-fuel-sensor" activeClassName="active">Z1 Tracker FS</NavLink>
                    <NavLink to="/z1-tracker-employee-attendence" activeClassName="active">Z1 Tracker EA</NavLink>
                  </div>
                </li>
                <li className="navbar-item has-dropdown">
                  <span className="navbar-link">Services</span>
                  <div className="navbar-dropdown">
                    <NavLink to="/bike-gps-tracker" activeClassName="active">Bike GPS Tracker</NavLink>
                    <NavLink to="car-gps-tracker" activeClassName="active">Car GPS Tracker</NavLink>
                    <NavLink to="/fleet-tracking-system" activeClassName="active">Fleet Tracking System</NavLink>
                    <NavLink to="/bus-gps-tracker" activeClassName="active">Bus GPS Tracker</NavLink>
                    <NavLink to="/school-bus-tracking-system" activeClassName="active">School Bus Tracking System</NavLink>
                    <NavLink to="/online-attendance" activeClassName="active">Employee Online Attendance with Live Tracking</NavLink>
                  </div>
                </li>
                <li>
                  <NavLink to="/features" activeClassName="active" onClick={() => setIsOpen(false)}>Features</NavLink>
                </li>
                <li>
                  <NavLink to="/blog" activeClassName="active" onClick={() => setIsOpen(false)}>Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/contactus" activeClassName="active" onClick={() => setIsOpen(false)}>ContactUs</NavLink>
                </li>
              </ul>
            </div>
            <div className="login-button">
              <NavLink to="http://103.90.86.173:8082" className="login-link">Login</NavLink>
            </div>
            <div className="order-button">
              <NavLink to="/order" className="login-link">Order Now</NavLink>
            </div>
            {/* Mobile menu toggle */}
            <div className="mobile-menu-toggle" onClick={toggleMenu}>
              {isOpen ? <IoCloseSharp /> : <IoMenuSharp />}
            </div>
          </div>
        </nav>

        {/* Mobile menu overlay */}
        <div className={`mobile-menu-overlay ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen(false)}></div>

        {/* Mobile menu */}
        <div className={`mobile-menu ${isOpen ? 'active' : ''}`}>
          <div className="mobile-menu-content">
            <ul>
              <li>
                <NavLink to="/" exact activeClassName="active" onClick={() => setIsOpen(false)}>Home</NavLink>
              </li>
              <li>
                <span className="navbar-link">Products</span>
                <ul>
                  <li><NavLink to="/gps-tracking-devices/Z1-tracking-micro" activeClassName="active">Z1 Tracker Basic</NavLink></li>
                  <li><NavLink to="/gps-tracking-devices/Z1-tracking-micro-plus" activeClassName="active">Z1 Tracker EOF</NavLink></li>
                  <li><NavLink to="/gps-tracking-devices/Z1-tracking-micro" activeClassName="active">Z1 Tracker FS</NavLink></li>
                  <li><NavLink to="/gps-tracking-devices/Z1-tracking-micro-plus" activeClassName="active">Z1 Tracker EA</NavLink></li>
                </ul>
              </li>
              <li>
                <span className="navbar-link">Services</span>
                <ul>
                  <li><NavLink to="/bike-gps-tracker" activeClassName="active">Bike GPS Tracker</NavLink></li>
                  <li><NavLink to="/car-gps-tracker" activeClassName="active">Car GPS Tracker</NavLink></li>
                  <li><NavLink to="/fleet-tracking-system" activeClassName="active">Fleet Tracking System</NavLink></li>
                  <li><NavLink to="/bus-gps-tracker" activeClassName="active">Bus GPS Tracker</NavLink></li>
                  <li><NavLink to="/school-bus-tracking-system" activeClassName="active">School Bus Tracking System</NavLink></li>
                  <li><NavLink to="/online-attendance" activeClassName="active">Employee Online Attendance with Live Tracking</NavLink></li>
                </ul>
              </li>
              <li>
                <NavLink to="/features" activeClassName="active" onClick={() => setIsOpen(false)}>Features</NavLink>
              </li>
              <li>
                <NavLink to="/blog" activeClassName="active" onClick={() => setIsOpen(false)}>Blog</NavLink>
              </li>
              <li>
                <NavLink to="/contactus" activeClassName="active" onClick={() => setIsOpen(false)}>ContactUs</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;




